import { getAuth, updateProfile } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../firebase";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import TextField from "@mui/material/TextField";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { PiSignOutBold } from "react-icons/pi";
import { FormControl, InputLabel, MenuItem, Select, Chip } from "@mui/material";
import { LiaUserEditSolid } from "react-icons/lia";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GrPrevious, GrNext } from "react-icons/gr";
import { MdDelete, MdEdit, MdDone, MdClose } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { MdPending, MdCancel, MdCalendarMonth } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinner";
import Bar from "../components/Bar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";


export default function MyListings() {
  const { t } = useTranslation();
  const auth = getAuth();
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const listingsPerPage = 8;
  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = listings.slice(
    indexOfFirstListing,
    indexOfLastListing
  );
  const [subscriptionType, setSubscriptionType] = useState("");
  const maxListingsPerSubscription = {
    essential: 5, // Example: 10 listings for Basic subscription
    premium: 50, // Example: 50 listings for Premium subscription
    unlimited: Infinity, // Example: Unlimited listings
  };
  const [maxAllowedListings, setMaxAllowedListings] = useState(0);
  const [remainingListings, setRemainingListings] = useState(0);

  const totalPages = Math.ceil(listings.length / listingsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const [openCalendar, setOpenCalendar] = useState(false);

  // Handle opening the calendar dialog
  const handleOpenCalendar = (listingId) => {
    setOpenCalendar(true);
    fetchAppointments(listingId); // Fetch the appointments for the specific listing
  };

  // Handle closing the calendar dialog
  const handleCloseCalendar = () => {
    setOpenCalendar(false);
  };

  const [isAgentUser, setIsAgentUser] = useState(false);
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      try {
        setLoading(true);

        // Fetch agent subscription type
        const agentDocRef = doc(db, "agents", auth.currentUser.uid);
        const agentDoc = await getDoc(agentDocRef);
        let subscription = "";
        let maxListings = 0;
        if (agentDoc.exists()) {
          const agentData = agentDoc.data();
          subscription = agentData.subscriptionType || "";
          maxListings = maxListingsPerSubscription[subscription] || 0;

          if (isMounted) {
            setSubscriptionType(subscription);
            setIsAgentUser(true);
          }
        } else if (isMounted) {
          setIsAgentUser(false);
        }

        // Fetch user listings
        const listingRef = collection(db, "listings");
        const q = query(
          listingRef,
          where("userRef", "==", auth.currentUser.uid),
          orderBy("timestamp", "desc")
        );
        const querySnap = await getDocs(q);

        let listings = [];
        querySnap.forEach((doc) => {
          listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });

        if (isMounted) {
          setListings(listings);
          // Fetch appointments for each listing
          for (let listing of listings) {
            await fetchAppointments(listing.id); // Fetch appointments for each listing
          }

          setLoading(false);
          // Calculate remaining listings
          const remaining =
            maxListings === Infinity ? "∞" : maxListings - listings.length;
          setRemainingListings(remaining);
          setMaxAllowedListings(maxListings);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (isMounted) setLoading(false);
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [auth.currentUser.uid]);

  const fetchAppointments = async (listingId) => {
    try {
      const appointmentRef = collection(db, "visits");
      const q = query(appointmentRef, where("listingId", "==", listingId));
      const querySnap = await getDocs(q);

      let appointmentsData = [];
      querySnap.forEach((doc) => {
        const data = doc.data();
        appointmentsData.push({
          id: doc.id,
          start: data.appointmentTime.toDate(),
          end: data.appointmentTimeEnd.toDate(),
          phoneNumber: data.userPhoneNumber,
          confirmStatus: data.confirmStatus,
        });
      });

      // Filter appointments to count only those with confirmStatus === false
      const filteredAppointments = appointmentsData.filter(
        (appointment) => appointment.confirmStatus === false
      );

      // Update the listing with the filtered appointment count
      setListings((prevListings) =>
        prevListings.map((listing) =>
          listing.id === listingId
            ? { ...listing, appointmentCount: filteredAppointments.length }
            : listing
        )
      );

      setAppointments(appointmentsData);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };


  const confirmVisit = async (appointmentId) => {
    try {
      // Fetch the appointment to be confirmed
      const visitRef = doc(db, "visits", appointmentId);
      const visitSnap = await getDoc(visitRef);
      const appointmentToConfirm = visitSnap.data();

      if (!appointmentToConfirm) {
        console.error("Appointment not found");
        return;
      }

      // Query to check for overlapping confirmed visits
      const appointmentRef = collection(db, "visits");
      const q = query(
        appointmentRef,
        where("listingId", "==", appointmentToConfirm.listingId),
        where("confirmStatus", "==", true),
        where("appointmentTime", "<=", appointmentToConfirm.appointmentTimeEnd),
        where("appointmentTimeEnd", ">=", appointmentToConfirm.appointmentTime)
      );
      const querySnap = await getDocs(q);

      if (!querySnap.empty) {
        // If there's an overlapping confirmed visit, show an error toast
        toast.error(t("confirmVisitError"));
        return;
      }

      // Proceed to confirm the visit if no conflicts are found
      await updateDoc(visitRef, {
        confirmStatus: true,
      });

      // Update the local state to reflect the change
      setAppointments((prevAppointments) =>
        prevAppointments.map((appointment) =>
          appointment.id === appointmentId
            ? { ...appointment, confirmStatus: true }
            : appointment
        )
      );

      // Provide feedback to the user
      console.log("Visit confirmed");
    } catch (error) {
      console.error("Error confirming visit:", error);
    }
  };


  const cancelVisit = async (appointmentId) => {
    try {
      const visitRef = doc(db, "visits", appointmentId);
      await updateDoc(visitRef, {
        confirmStatus: false, // Revert the visit status back to unconfirmed
      });

      // Update the local state to reflect the change
      setAppointments((prevAppointments) =>
        prevAppointments.map((appointment) =>
          appointment.id === appointmentId
            ? { ...appointment, confirmStatus: false }
            : appointment
        )
      );

      // Optionally, you can provide feedback to the user
      console.log("Visit cancelled");
    } catch (error) {
      console.error("Error updating visit status:", error);
    }
  };

  const deleteVisit = async (appointmentId) => {
    try {
      // Reference to the visit document
      const visitRef = doc(db, "visits", appointmentId);

      // Delete the visit document
      await deleteDoc(visitRef);

      // Update the local state to remove the appointment
      setAppointments((prevAppointments) =>
        prevAppointments.filter(
          (appointment) => appointment.id !== appointmentId
        )
      );

      // Provide feedback to the user
      console.log("Visit deleted successfully");
    } catch (error) {
      console.error("Error deleting visit:", error);
    }
  };

  




  async function onDelete(listingID) {
    if (window.confirm(t("deleteComfirmation"))) {
      try {
        // Retrieve the listing document
        const listingDoc = doc(db, "listings", listingID);
        const listingSnapshot = await getDoc(listingDoc);

        if (listingSnapshot.exists()) {
          const listingData = listingSnapshot.data();

          // Delete images from storage
          if (listingData.imgUrls && listingData.imgUrls.length > 0) {
            const storage = getStorage();

            await Promise.all(
              listingData.imgUrls.map(async (url) => {
                const fileRef = ref(storage, url);
                await deleteObject(fileRef);
              })
            );
          }

          // Delete the listing document
          await deleteDoc(listingDoc);

          // Update state and show success message
          const updatedListings = listings.filter(
            (listing) => listing.id !== listingID
          );
          setListings(updatedListings);
          toast.success("Successfully deleted listing");
        } else {
          toast.error("Listing not found");
        }
      } catch (error) {
        console.error("Error deleting listing or images:", error);
        toast.error("Error deleting listing");
      }
    }
  }

  function onEdit(listingID) {
    navigate(`/edit-listing/${listingID}`);
  }

  const isAgent = async () => {
    const agentDocRef = doc(db, "agents", auth.currentUser.uid);
    const agentDoc = await getDoc(agentDocRef);
    return agentDoc.exists();
  };

  async function handleRequestSold(listingID) {
    try {
      // Prompt the user for confirmation
      const confirmChange = window.confirm(t("markassoldtext"));

      if (confirmChange) {
        // Reference to the listing document
        const listingRef = doc(db, "listings", listingID);

        // Update the listing status to "pending approval" or another intermediate state
        await updateDoc(listingRef, {
          status: "pendingsold", // You might use another status like "sold pending verification"
        });

        toast.success("We will contact you to verify the sale.");
      }
    } catch (error) {
      console.error("Error updating listing status:", error);
      toast.error("Failed to update listing status.");
    }
  }

  const [tooltip, setTooltip] = useState("");

  const handleIconClick = (event, status) => {
    event.stopPropagation();
    event.preventDefault();

    switch (status) {
      case "pending":
        setTooltip("This listing is pending approval.");
        break;
      case "approved":
        setTooltip("This listing has been approved.");
        break;
      case "sold":
        setTooltip("This listing has been marked as sold.");
        break;
      case "pendingsold":
        setTooltip("This listing is pending to be marked as sold.");
        break;
      case "noanswer":
        setTooltip("No response received for this listing.");
        break;
      case "noanswertwo":
        setTooltip("Second follow-up with no response.");
        break;
      case "noanswerthree":
        setTooltip("Third follow-up with no response.");
        break;
      case "canceled":
        setTooltip("This listing has been canceled.");
        break;
      default:
        setTooltip("Unknown status.");
    }
  };

  const handleMouseEnter = (status) => {
    switch (status) {
      case "pending":
        setTooltip("This listing is pending approval.");
        break;
      case "approved":
        setTooltip("This listing has been approved.");
        break;
      case "sold":
        setTooltip("This listing has been marked as sold.");
        break;
      case "pendingsold":
        setTooltip("This listing is pending to be marked as sold.");
        break;
      case "noanswerone":
        setTooltip("No response received for this listing.");
        break;
      case "noanswertwo":
        setTooltip("Second follow-up with no response.");
        break;
      case "noanswerthree":
        setTooltip("Third follow-up with no response.");
        break;
      case "canceled":
        setTooltip("This listing has been canceled.");
        break;
      default:
        setTooltip("Unknown status.");
    }
  };

  const handleMouseLeave = () => {
    setTooltip("");
  };

  

  return (
    <div className="mb-8">
      {loading ? (
        <div className="items-center flex w-full justify-center ">
          <Bar loading={loading} />
        </div>
      ) : (
        <div className="max-w-6xl px-8 mt-8 md:mt-16 mx-auto  h-full">
          <div className="px-4 py-4 bg-gray-50 border rounded-xl mb-2">
            <div>
              <h2 className="cursor-pointer text-xl md:text-3xl custom-font font-bold mb-4">
                {t("mylistings")}
              </h2>
              {isAgentUser && (
                <>
                  <div className="hidden">
                    <h2 className="flex gap-2 capitalize md:text-xl">
                      <p>
                        {" "}
                        {subscriptionType
                          ? t(subscriptionType)
                          : t("noSubscription")}{" "}
                        Pack
                      </p>
                      <button className="cursor-pointer bg-gradient-to-r from-orange-500 to-red-500 text-xs px-2 text-white rounded-xl font-medium ring-black ring-opacity-10 gradient element-to-rotate">
                        Upgrade
                      </button>
                    </h2>

                    <h2 className="text-sm md:text-base">
                      {listings.length} /{" "}
                      {maxAllowedListings === Infinity
                        ? t("unlimited")
                        : maxAllowedListings}{" "}
                      {t("maxListingsAllowed")}
                    </h2>
                    <h2 className="text-sm text-red-600 md:text-base mb-4">
                      {remainingListings} {t("remainingListings")}
                    </h2>
                  </div>
                </>
              )}
            </div>
            <div className="items-center grid grid-cols-2 md:grid-cols-3 md:w-2/3 text-sm md:text-base mb-4 ">
              <div className="flex items-center gap-2">
                <MdPending className="text-md text-yellow-500" />{" "}
                <div>{t("pending")}</div>
              </div>
              <div className="flex items-center gap-2">
                <MdPending className="text-md text-blue-500" />
                <div>{t("contacted")}</div>
              </div>
              <div className="flex items-center gap-2">
                <RiMoneyDollarCircleFill className="text-md text-yellow-500" />{" "}
                <div>{t("pendingsold")}</div>
              </div>
              <div className="flex items-center gap-2">
                <FaCheckCircle className="text-sm text-green-500" />
                <div>{t("approved")}</div>
              </div>
              <div className="flex items-center gap-2">
                <MdCancel className="text-md text-red-500" />
                <div>{t("canceled")}</div>
              </div>
              <div className="flex items-center gap-2">
                <RiMoneyDollarCircleFill className="text-md text-green-500" />
                <div>{t("sold")}</div>
              </div>
            </div>
          </div>

          <section className="bg-gray-50 rounded-xl max-w-6xl border mx-auto flex justify-center items-center  flex-col">
            <div className="w-full ">
              <div className="mb-4  px-4">
                <div className="">
                  <>
                    {!loading && listings.length > 0 && (
                      <ul className="flex md:gap-10 py-2 md:py-4  font-semibold"></ul>
                    )}
                    <ul className="">
                      {!loading && listings.length > 0 ? (
                        currentListings.map((listing) => (
                          <li key={listing.id} className="text-xs  sm:text-sm ">
                            <div className="flex md:px-2 py-2 md:py-6 hover:bg-gray-100 rounded-xl">
                              <div
                                className={`flex w-full ${
                                  listing.data.status === "approved"
                                    ? "cursor-pointer"
                                    : "cursor-not-allowed"
                                }`}
                                onClick={() => {
                                  if (listing.data.status === "approved") {
                                    navigate(`/listingdetails/${listing.id}`);
                                  }
                                }}
                              >
                                <div className="flex w-2/4 lg:w-2/6">
                                  <img
                                    src={
                                      listing.data.imgUrls ||
                                      "/placeholder-image.jpg"
                                    }
                                    alt="Listing Image"
                                    className="w-3/4 h-16 md:h-20 object-cover relative rounded-xl ml-2 mb-2"
                                  />
                                </div>
                                <div className=" ">
                                  <p className="flex font-semibold capitalize mb-1">
                                    {listing.data.listingType}
                                  </p>
                                  <p className=" hidden sm:flex capitalize  text-gray-700 mb-1">
                                    {listing.data.address.slice(0, 20)}
                                    {listing.data.address.length > 20 && "..."}
                                    {listing.data.address.length}
                                  </p>
                                  <p className="flex font-semibold ">
                                    {new Intl.NumberFormat().format(
                                      listing.data.regularPrice
                                    )}{" "}
                                    DH{" "}
                                  </p>
                                </div>

                                <div className="flex absolute pl-[1px] items-center">
                                  <span
                                    className="flex justify-center items-center border-2 border-gray-100 rounded-full"
                                    style={{
                                      backgroundColor:
                                        listing.data.status === "pending" ||
                                        listing.data.status === "approved" ||
                                        listing.data.status === "sold" ||
                                        listing.data.status === "pendingsold" ||
                                        listing.data.status === "canceled" ||
                                        listing.data.status === "noanswerone" ||
                                        listing.data.status === "noanswertwo" ||
                                        listing.data.status === "noanswerthree"
                                          ? "white"
                                          : "gray",
                                      color:
                                        listing.data.status === "pending"
                                          ? "black"
                                          : "white",
                                    }}
                                    onClick={(e) =>
                                      handleIconClick(e, listing.data.status)
                                    }
                                    onMouseEnter={() =>
                                      handleMouseEnter(listing.data.status)
                                    }
                                    onMouseLeave={handleMouseLeave}
                                    title={tooltip} // Tooltip text
                                  >
                                    {listing.data.status === "pending" ? (
                                      <MdPending className="text-md text-yellow-500" />
                                    ) : listing.data.status ===
                                      "pendingsold" ? (
                                      <RiMoneyDollarCircleFill className="text-md text-yellow-500" />
                                    ) : listing.data.status === "canceled" ? (
                                      <MdCancel className="text-md text-red-500" />
                                    ) : listing.data.status === "sold" ? (
                                      <RiMoneyDollarCircleFill className="text-md text-green-500" />
                                    ) : listing.data.status === "noanswertwo" ||
                                      listing.data.status === "noanswerthree" ||
                                      listing.data.status === "noanswerone" ? (
                                      <MdPending className="text-md text-blue-500" />
                                    ) : listing.data.status === "approved" ? (
                                      <FaCheckCircle className="text-sm text-green-500" />
                                    ) : (
                                      <MdPending className="text-md text-red-500" />
                                    )}
                                  </span>
                                </div>
                              </div>

                              <div className="ml-auto pl-8 w-1/3 sm:w-full items-center ">
                                <p className="flex w-full items-center justify-end">
                                  <div className="relative">
                                    <MdCalendarMonth
                                      className="cursor-pointer text-xl hover:opacity-50 mr-0.5"
                                      onClick={() =>
                                        handleOpenCalendar(listing.id)
                                      }
                                    />
                                    {listing.appointmentCount > 0 && (
                                      <span
                                        onClick={() =>
                                          handleOpenCalendar(listing.id)
                                        }
                                        className="absolute cursor-pointer text-small -top-2 -right-1 bg-red-500 text-white rounded-full px-1.5 flex items-center justify-center"
                                      >
                                        {listing.appointmentCount}
                                      </span>
                                    )}
                                  </div>
                                  <MdEdit
                                    className="cursor-pointer text-xl hover:opacity-50 "
                                    onClick={() => onEdit(listing.id)}
                                  />
                                  <MdDelete
                                    className="cursor-pointer text-xl hover:opacity-50 text-red-700"
                                    onClick={() => onDelete(listing.id)}
                                  />
                                </p>
                                {listing.data.status === "approved" &&
                                  isAgentUser && (
                                    <button
                                      onClick={() =>
                                        handleRequestSold(listing.id)
                                      }
                                      className="bg-gray-100 hover:bg-gray-200 text-black border-2 border-gray-300 flex ml-auto mt-2 justify-end  p-2 rounded-xl"
                                    >
                                      {t("markassold")}
                                    </button>
                                  )}
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <div className="mt-4"> {t("youcurrentlyhave")}</div>
                      )}
                    </ul>
                  </>
                </div>
              </div>
              <div className="hidden md:flex mt-4 mb-4 font-semibold pagination gap-1 justify-center">
                {listings.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "40px", // Adjust width as needed for circular buttons
                          height: "40px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {listings.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
              <div className="flex md:hidden mt-4 mb-4 font-semibold pagination gap-1 justify-center">
                {listings.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "30px", // Adjust width as needed for circular buttons
                          height: "30px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {listings.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>

              <div className="border-t  flex-1 mt-6 after:border-gray-300"></div>
            </div>
          </section>
        </div>
      )}
      <Dialog
        className="justify-center items-center text-center "
        open={openCalendar}
        onClose={handleCloseCalendar}
      >
        <DialogContent>
          <DialogContentText>
            <div className="text-xs sm:text-base">
              <div className="flex gap-2 mb-4">
                <h3 className="text-left">
                  {t("bookings")} ({appointments.length})
                </h3>
                {appointments.length > 0 && (
                  <p className="flex gap-0.5 items-center ml-auto">
                    {" "}
                    <MdDone className="ml-auto text-green-600 text-sm sm:text-lg" />
                    {t("accept")}
                    <MdClose className="ml-auto text-orange-600 text-sm sm:text-lg" />
                    {t("cancel")}
                    <MdDelete className="ml-auto text-red-600 text-sm sm:text-lg" />
                    {t("delete")}
                  </p>
                )}
              </div>

              <ul>
                {appointments.length > 0 ? (
                  appointments.map((appointment) => (
                    <li
                      className={`items-center hover:bg-gray-200 rounded-lg mb-1 flex px-2 transition-all ease-in-out duration-150 ${
                        appointment.confirmStatus
                          ? "bg-green-100 hover:bg-green-200"
                          : "bg-gray-100"
                      }`}
                      key={appointment.id}
                    >
                      <p className="w-full justify-start text-left">
                        {appointment.start.toLocaleString()} {" "}
                        {/* Show Confirm Icon if not confirmed */}
                      </p>
                      <p className="mr-2 text-red-600">
                        {appointment.phoneNumber}
                      </p>

                      {!appointment.confirmStatus && (
                        <MdDone
                          className="ml-auto text-green-600 text-2xl sm:text-3xl cursor-pointer"
                          onClick={() => confirmVisit(appointment.id)}
                        />
                      )}
                      {/* Show Cancel Icon if confirmed */}
                      {appointment.confirmStatus && (
                        <MdClose
                          className="ml-auto text-orange-600 text-2xl sm:text-3xl cursor-pointer"
                          onClick={() => cancelVisit(appointment.id)}
                        />
                      )}
                      {/* Show Cancel Icon if confirmed */}
                      <MdDelete
                        className="ml-auto text-red-600 text-2xl sm:text-3xl cursor-pointer"
                        onClick={() => deleteVisit(appointment.id)}
                      />
                    </li>
                  ))
                ) : (
                  <p>{t("noVisits")}</p>
                )}
              </ul>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
