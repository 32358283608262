// utils/priceEstimator.js
import { areas } from "./areas";

export const conditionModifiers = {
  brandnew: 1.2, // Increase price by 20%
  perfect: 1.1, // Increase price by 10%
  good: 1, // Increase price by 0%
  renovation: 0.8, // Decrease price by 20%
};

export const sunExposureModifiers = {
  allday: 1.1, // Increase price by 10%
  morning: 1.05, // Increase price by 5%
  afternoon: 1, // Increase price by 0%
  nosun: 0.9, // Decrease price by 10%
};

export const isPointInPolygon = (point, polygon) => {
  const { lat, lng } = point;
  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].lng;
    const yi = polygon[i].lat;
    const xj = polygon[j].lng;
    const yj = polygon[j].lat;

    const intersect =
      yi > lat !== yj > lat && lng < ((xj - xi) * (lat - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};

export const estimatePrice = (listingData) => {
  const {
    latitude,
    longitude,
    size,
    condition,
    sunExposure,
    yearBuilt,
    listingType,
  } = listingData; // Add listingType to destructure

  let basePrice = 0;
  let matchingAreas = [];

  // Find all areas that the point falls into
  for (const area of areas) {
    const isInside = isPointInPolygon(
      { lat: latitude, lng: longitude },
      area.coordinates
    );

    if (isInside) {
      matchingAreas.push(area);
    }
  }

  // If no areas are found, return null (unavailable)
  if (matchingAreas.length === 0) {
    return null;
  }

  // Check if the required price is available based on listing type
  const priceAvailable = matchingAreas.some((area) => {
    if (
      listingType === "villa" &&
      area.pricePerSquareMeterVilla !== undefined
    ) {
      return true;
    }
    if (
      listingType !== "villa" &&
      area.pricePerSquareMeterOther !== undefined
    ) {
      return true;
    }
    return false;
  });

  // If price is not available, return null
  if (!priceAvailable) {
    return null;
  }

  // Calculate average price per square meter for all matching areas
  const totalPricePerSquareMeter = matchingAreas.reduce((sum, area) => {
    // Use different price based on listing type
    const pricePerSquareMeter =
      listingType === "villa"
        ? area.pricePerSquareMeterVilla || 0 // Default to 0 if undefined
        : area.pricePerSquareMeterOther || 0; // Default to 0 if undefined

    return sum + pricePerSquareMeter;
  }, 0);

  const avgPricePerSquareMeter =
    totalPricePerSquareMeter / matchingAreas.length;

  basePrice = avgPricePerSquareMeter * size; // Base price = avg price per sqm * size

  // Apply condition modifier
  const conditionModifier = conditionModifiers[condition] || 1;
  basePrice *= conditionModifier;

  // Apply sun exposure modifier
  const sunExposureModifier = sunExposureModifiers[sunExposure] || 1;
  basePrice *= sunExposureModifier;

  // Adjust price based on the age of the property
  const currentYear = new Date().getFullYear();
  const age = currentYear - yearBuilt;

  if (age > 30) {
    basePrice *= 0.8; // Decrease price for homes older than 30 years
  } else if (age > 20) {
    basePrice *= 0.9; // Decrease price for homes older than 20 years
  }

  // Round down to the nearest thousand
  const roundedPrice = Math.floor(basePrice / 1000) * 1000;

  return roundedPrice;
};







