import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  limit,
  getDoc,
  doc,
  deleteDoc,
  orderBy,
  updateDoc,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  deleteObject,
} from "firebase/storage";
import { db } from "../firebase";
import { Link, useNavigate } from "react-router-dom";
import { GrPrevious, GrNext } from "react-icons/gr";
import { CiLaptop } from "react-icons/ci";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { useTranslation } from "react-i18next";
import { MdDelete, MdDone } from "react-icons/md";
import ReactMapGL, { Source, Layer, Marker } from "react-map-gl";
import { areas } from "../utils/areas"; // Import your areas
import { TbRefresh } from "react-icons/tb";





export default function AdminPanel() {
  const { t } = useTranslation();
  const [selectedSection, setSelectedSection] = useState("listings");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalListings, setTotalListings] = useState(0);
  const [newListings, setNewListings] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [beyttySearchQuery, setBeyttySearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [beyttyStatusFilter, setBeyttyStatusFilter] = useState("");
  const [pendingListingsCount, setPendingListingsCount] = useState(0);
  const [pendingBeyttyListingsCount, setPendingBeyttyListingsCount] =
    useState(0);
  const [pendingAgentsCount, setPendingAgentsCount] = useState(0);
  const [viewport, setViewport] = useState({
    width: "100%",
    height: 400,
    latitude: 33.5791, // Latitude for Morocco
    longitude: -7.626549, // Longitude for Morocco
    zoom: 11, // Adjust zoom level as needed
  });

  const [prevMonthUsers, setPrevMonthUsers] = useState(0);
  const [prevMonthListings, setPrevMonthListings] = useState(0);
  const [userGrowthPercentage, setUserGrowthPercentage] = useState(0);
  const [listingGrowthPercentage, setListingGrowthPercentage] = useState(0);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Total Users",
        data: [],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  });
  const [listings, setListings] = useState([]);
  const [beyttyListings, setBeyttyListings] = useState([]);
  const [language, setLanguage] = useState("");
  const [flaggedReviews, setFlaggedReviews] = useState([]);

  const [cities, setCities] = useState(""); // New state for selected city
  const [status, setStatus] = useState(""); // New state for selected city

  useEffect(() => {
    let isMounted = true; // Track if component is mounted

    async function fetchFlaggedReviews() {
      try {
        const reviewsCollection = collection(db, "agent_reviews");
        const q = query(
          reviewsCollection,
          where("flaggedForModeration", "==", true)
        );
        const querySnap = await getDocs(q);
        const flaggedReviewsData = [];
        querySnap.forEach((doc) => {
          flaggedReviewsData.push({ id: doc.id, data: doc.data() });
        });
        if (isMounted) setFlaggedReviews(flaggedReviewsData);
      } catch (error) {
        console.error("Error fetching flagged reviews:", error);
      }
    }

    fetchFlaggedReviews();

    return () => {
      isMounted = false; // Cleanup function to set isMounted to false
    };
  }, []);

  // Function to calculate the centroid of a polygon
  const calculateCentroid = (coordinates) => {
    const latSum = coordinates.reduce((sum, coord) => sum + coord.lat, 0);
    const lngSum = coordinates.reduce((sum, coord) => sum + coord.lng, 0);
    const lat = latSum / coordinates.length;
    const lng = lngSum / coordinates.length;
    return { lat, lng };
  };

  // Determine min and max price per square meter based on both price per square meter for villas and other properties
  const prices = areas
    .map((area) => {
      const villaPrice = area.pricePerSquareMeterVilla || null; // Use null for missing values
      const otherPrice = area.pricePerSquareMeterOther || null; // Use null for missing values

      // Log areas without prices
      if (!villaPrice && !otherPrice) {
        console.warn(`Missing prices for area: ${area.name}`);
        return null; // Skip areas with no prices
      }

      // Calculate average only if both prices are available
      if (villaPrice !== null && otherPrice !== null) {
        return (villaPrice + otherPrice) / 2; // Average of the two prices
      }

      // If one price is missing, use the other price (or consider a default)
      return villaPrice !== null ? villaPrice : otherPrice;
    })
    .filter((price) => price !== null); // Filter out null prices

  const minPrice = prices.length > 0 ? Math.min(...prices) : 0; // Handle case with no valid prices
  const maxPrice = prices.length > 0 ? Math.max(...prices) : 1; // Prevent division by zero later

  console.log("Prices:", prices);
  console.log("Min Price:", minPrice);
  console.log("Max Price:", maxPrice);

  // Function to get color based on average price per square meter (gradient from green to red)
  const getColorByPrice = (area) => {
    const villaPrice = area.pricePerSquareMeterVilla || null;
    const otherPrice = area.pricePerSquareMeterOther || null;

    // Calculate the average price if both are available
    const averagePrice =
      villaPrice !== null && otherPrice !== null
        ? (villaPrice + otherPrice) / 2
        : villaPrice !== null
        ? villaPrice
        : otherPrice;

    const priceRange = maxPrice - minPrice;

    // Check for edge case where price range is 0
    if (priceRange === 0) {
      return "rgb(255, 0, 0)"; // Default to red if all prices are the same or invalid
    }

    // Normalize the average price to a value between 0 and 1
    const normalizedPrice = (averagePrice - minPrice) / priceRange;

    let red = 0;
    let green = 0;
    let blue = 0;

    // Determine color based on normalized price
    if (normalizedPrice < 0.5) {
      // Transition from green to yellow
      green = 255; // Full green
      red = Math.round(255 * (normalizedPrice * 2)); // Increase red from 0 to 255
    } else {
      // Transition from yellow to red
      red = 255; // Full red
      green = Math.round(255 * (1 - (normalizedPrice - 0.5) * 2)); // Decrease green from 255 to 0
    }

    return `rgb(${red}, ${green}, ${blue})`; // Return color as an RGB string
  };

  const renderPolygons = () => {
    return areas.map((area, index) => {
      const coordinates = area.coordinates.map((coord) => [
        coord.lng,
        coord.lat,
      ]);
      const fillColor = getColorByPrice(area); // Get color based on average price

      const geojson = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [coordinates],
        },
      };

      // Calculate the centroid of the area for placing the text
      const { lat, lng } = calculateCentroid(area.coordinates);

      return (
        <Source key={index} id={area.name} type="geojson" data={geojson}>
          <Layer
            id={`${area.name}-fill`}
            type="fill"
            paint={{
              "fill-color": fillColor,
              "fill-opacity": 0.5,
            }}
          />
          <Layer
            id={`${area.name}-outline`}
            type="line"
            paint={{
              "line-color": "#000",
              "line-width": 1,
            }}
          />
          {/* Render the area name if the zoom level is greater than 8 */}
          <Marker
            latitude={lat}
            longitude={lng}
            offsetTop={-20} // Adjust as necessary
            offsetLeft={-20} // Adjust as necessary
          >
            <div
              style={{
                color: "black",
                borderRadius: "3px",
              }}
            >
              {area.name}
            </div>
          </Marker>
        </Source>
      );
    });
  };

  // Function to delete a review
  const handleDeleteReview = async (reviewId) => {
    try {
      const reviewRef = doc(db, "agent_reviews", reviewId);
      await deleteDoc(reviewRef);
      setFlaggedReviews((prev) =>
        prev.filter((review) => review.id !== reviewId)
      );
    } catch (error) {
      console.error("Error deleting review:", error);
    }
  };

  const clearReportReview = async (reviewId) => {
    try {
      // Fetch the review document from Firestore
      const reviewDocRef = doc(db, "agent_reviews", reviewId);
      const reviewDoc = await getDoc(reviewDocRef);

      const reviewData = reviewDoc.data();

      // Clear reports
      await updateDoc(reviewDocRef, {
        reportCount: 0, // Reset report count
        reportedBy: [], // Clear the list of users who reported
        flaggedForModeration: false, // Unflag the review
      });
    } catch (error) {
      console.error("Error clearing report from review:", error);
    }
  };

  useEffect(() => {
    let isMounted = true;

    async function fetchListings() {
      try {
        const listingRef = collection(db, "listings");
        const q = query(listingRef, orderBy("timestamp", "desc"));
        const querySnap = await getDocs(q);
        let listings = [];
        querySnap.forEach((doc) => {
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        if (isMounted) {
          setListings(listings);
        }
      } catch (error) {}
    }

    fetchListings();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true; // Track if component is mounted

    const fetchBeyttyListings = async () => {
      try {
        const listingRef = collection(db, "beyttylistings");
        const q = query(listingRef, orderBy("timestamp", "desc"));
        const querySnap = await getDocs(q);
        let beyttyListings = [];
        querySnap.forEach((doc) => {
          beyttyListings.push({ id: doc.id, data: doc.data() });
        });
        if (isMounted) setBeyttyListings(beyttyListings);
      } catch (error) {
        console.error("Error fetching beytty listings:", error);
      }
    };

    fetchBeyttyListings();

    return () => {
      isMounted = false; // Cleanup function to set isMounted to false
    };
  }, []);

  // Calculate count of pending regular and sold listings
  useEffect(() => {
    const countPendingListings = listings.filter(
      (listing) =>
        listing.data.status === "pending" ||
        listing.data.status === "pendingsold"
    ).length;
    setPendingListingsCount(countPendingListings);
  }, [listings]);

  // Calculate count of pending beytty listings
  useEffect(() => {
    const countPendingBeyttyListings = beyttyListings.filter(
      (listing) => listing.data.status === "pending"
    ).length;
    setPendingBeyttyListingsCount(countPendingBeyttyListings);
  }, [beyttyListings]);

  const [currentPage, setCurrentPage] = useState(1);
  const listingsPerPage = 10;
  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = listings.slice(
    indexOfFirstListing,
    indexOfLastListing
  );
  const currentBeyttyListings = beyttyListings.slice(
    indexOfFirstListing,
    indexOfLastListing
  );

  const totalPages = Math.ceil(listings.length / listingsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  async function onDelete(listingID) {
    if (window.confirm(t("deleteComfirmation"))) {
      try {
        // Retrieve the listing document
        const listingDoc = doc(db, "listings", listingID);
        const listingSnapshot = await getDoc(listingDoc);

        if (listingSnapshot.exists()) {
          const listingData = listingSnapshot.data();

          // Delete images from storage
          if (listingData.imgUrls && listingData.imgUrls.length > 0) {
            const storage = getStorage();

            await Promise.all(
              listingData.imgUrls.map(async (url) => {
                const fileRef = ref(storage, url);
                await deleteObject(fileRef);
              })
            );
          }

          // Delete the listing document
          await deleteDoc(listingDoc);

          // Update state and show success message
          const updatedListings = listings.filter(
            (listing) => listing.id !== listingID
          );
          setListings(updatedListings);
          toast.success("Successfully deleted listing");
        } else {
          toast.error("Listing not found");
        }
      } catch (error) {
        console.error("Error deleting listing or images:", error);
        toast.error("Error deleting listing");
      }
    }
  }

  async function onBeyttyDelete(listingID) {
    if (window.confirm(t("deleteComfirmation"))) {
      try {
        // Retrieve the listing document
        const listingDoc = doc(db, "beyttylistings", listingID);
        const listingSnapshot = await getDoc(listingDoc);

        if (listingSnapshot.exists()) {
          const listingData = listingSnapshot.data();

          // Delete images from storage
          if (listingData.imgUrls && listingData.imgUrls.length > 0) {
            const storage = getStorage();

            await Promise.all(
              listingData.imgUrls.map(async (url) => {
                const fileRef = ref(storage, url);
                await deleteObject(fileRef);
              })
            );
          }

          // Delete the listing document
          await deleteDoc(listingDoc);

          // Update state and show success message
          const updatedListings = listings.filter(
            (listing) => listing.id !== listingID
          );
          setListings(updatedListings);
          toast.success("Successfully deleted listing");
        } else {
          toast.error("Listing not found");
        }
      } catch (error) {
        console.error("Error deleting listing or images:", error);
        toast.error("Error deleting listing");
      }
    }
  }

  const handleStatusChange = async (e, listingId) => {
    const newStatus = e.target.value;

    // Ask for confirmation
    const confirmed = window.confirm(
      "Are you sure you want to change the status?"
    );

    if (confirmed) {
      try {
        // Update the status in the database
        await updateDoc(doc(db, "listings", listingId), {
          status: newStatus,
        });

        // Update the status locally
        setListings((prevListings) =>
          prevListings.map((listing) =>
            listing.id === listingId
              ? { ...listing, data: { ...listing.data, status: newStatus } }
              : listing
          )
        );
        // Update the status locally
        setBeyttyListings((prevListings) =>
          prevListings.map((listing) =>
            listing.id === listingId
              ? { ...listing, data: { ...listing.data, status: newStatus } }
              : listing
          )
        );
      } catch (error) {
        console.error("Error updating status:", error);
      }
    } else {
      // Revert the select to its previous value if the user cancels
      // You might need to store the previous status in state for this
      // Example: const [previousStatus, setPreviousStatus] = useState("");
      // And then set it before the confirmation dialog and revert to it here
    }
  };
  const handleBeyttyStatusChange = async (e, listingId) => {
    const newStatus = e.target.value;

    // Ask for confirmation
    const confirmed = window.confirm(
      "Are you sure you want to change the status?"
    );

    if (confirmed) {
      try {
        // Update the status in the database
        await updateDoc(doc(db, "beyttylistings", listingId), {
          status: newStatus,
        });

        // Update the status locally
        setBeyttyListings((prevListings) =>
          prevListings.map((listing) =>
            listing.id === listingId
              ? { ...listing, data: { ...listing.data, status: newStatus } }
              : listing
          )
        );
      } catch (error) {
        console.error("Error updating status:", error);
      }
    } else {
      // Revert the select to its previous value if the user cancels
      // You might need to store the previous status in state for this
      // Example: const [previousStatus, setPreviousStatus] = useState("");
      // And then set it before the confirmation dialog and revert to it here
    }
  };
  const handleAgentStatusChange = async (e, agentId) => {
    const newStatus = e.target.value;

    // Ask for confirmation
    const confirmed = window.confirm(
      "Are you sure you want to change the status?"
    );

    if (confirmed) {
      try {
        // Update the status in the database
        await updateDoc(doc(db, "agents", agentId), {
          status: newStatus,
        });
      } catch (error) {
        console.error("Error updating status:", error);
      }
    } else {
      // Revert the select to its previous value if the user cancels
      // You might need to store the previous status in state for this
      // Example: const [previousStatus, setPreviousStatus] = useState("");
      // And then set it before the confirmation dialog and revert to it here
    }
  };

  useEffect(() => {
    // Fetch total users
    const fetchTotalUsers = async () => {
      const userCollection = collection(db, "users");
      const userSnapshot = await getDocs(userCollection);
      setTotalUsers(userSnapshot.size);
    };

    // Fetch total listings
    const fetchTotalListings = async () => {
      const listingsCollection = collection(db, "listings");
      const listingsSnapshot = await getDocs(listingsCollection);
      setTotalListings(listingsSnapshot.size);
    };

    // Fetch new listings (e.g., added in the last 1 day)
    const fetchNewListings = async () => {
      const now = new Date();
      const fiveDaysAgo = new Date(now.setDate(now.getDate() - 1));
      const listingsCollection = collection(db, "listings");
      const listingsSnapshot = await getDocs(listingsCollection);
      const newListingsCount = listingsSnapshot.docs.filter((doc) => {
        const listingData = doc.data();
        const listingDate = listingData.timestamp.toDate();
        return listingDate >= fiveDaysAgo;
      }).length;
      setNewListings(newListingsCount);
    };

    // Fetch total users from the previous month
    const fetchPrevMonthUsers = async () => {
      const now = new Date();
      const firstDayPrevMonth = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        1
      );
      const lastDayPrevMonth = new Date(now.getFullYear(), now.getMonth(), 0);
      const userCollection = collection(db, "users");
      const q = query(
        userCollection,
        where("timestamp", ">=", Timestamp.fromDate(firstDayPrevMonth)),
        where("timestamp", "<=", Timestamp.fromDate(lastDayPrevMonth))
      );
      const userSnapshot = await getDocs(q);
      setPrevMonthUsers(userSnapshot.size);
    };

    // Fetch total listings from the previous month
    const fetchPrevMonthListings = async () => {
      const now = new Date();
      const firstDayPrevMonth = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        1
      );
      const lastDayPrevMonth = new Date(now.getFullYear(), now.getMonth(), 0);
      const listingsCollection = collection(db, "listings");
      const q = query(
        listingsCollection,
        where("timestamp", ">=", Timestamp.fromDate(firstDayPrevMonth)),
        where("timestamp", "<=", Timestamp.fromDate(lastDayPrevMonth))
      );
      const listingsSnapshot = await getDocs(q);
      setPrevMonthListings(listingsSnapshot.size);
    };

    fetchTotalUsers();
    fetchTotalListings();
    fetchNewListings();
    fetchPrevMonthUsers();
    fetchPrevMonthListings();
  }, []);

  useEffect(() => {
    // Calculate user growth percentage
    if (prevMonthUsers !== 0) {
      const growthPercentage =
        ((totalUsers - prevMonthUsers) / prevMonthUsers) * 100;
      setUserGrowthPercentage(growthPercentage.toFixed(2));
    }
  }, [totalUsers, prevMonthUsers]);

  useEffect(() => {
    // Calculate listing growth percentage
    if (prevMonthListings !== 0) {
      const growthPercentage =
        ((totalListings - prevMonthListings) / prevMonthListings) * 100;
      setListingGrowthPercentage(growthPercentage.toFixed(2));
    }
  }, [totalListings, prevMonthListings]);

  useEffect(() => {
    const fetchTotalUsersData = async () => {
      const userCollection = collection(db, "users");
      const userSnapshot = await getDocs(userCollection);
      const userData = userSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const labels = userData.map((data) => data.timestamp.toDate());
      const data = userData.map((data) => data.size);
      setChartData((prevChartData) => ({
        ...prevChartData,
        labels,
        datasets: [
          {
            ...prevChartData.datasets[0],
            data,
          },
        ],
      }));
    };

    fetchTotalUsersData();
  }, []);

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleBeyttyStatusFilterChange = (e) => {
    setBeyttyStatusFilter(e.target.value);
  };

  const filteredListings = listings.filter((listing) => {
    const matchesSearchQuery = listing.id.includes(searchQuery);
    const matchesStatusFilter = statusFilter
      ? listing.data.status === statusFilter
      : true;
    return matchesSearchQuery && matchesStatusFilter;
  });

  const filteredBeyttyListings = beyttyListings.filter((listing) => {
    const matchesBeyttySearchQuery = listing.id.includes(beyttySearchQuery);
    const matchesBeyttyStatusFilter = beyttyStatusFilter
      ? listing.data.status === beyttyStatusFilter
      : true;
    return matchesBeyttySearchQuery && matchesBeyttyStatusFilter;
  });

  const filteredAgents = agents.filter((agent) => {
    const nameMatch = `${agent.firstName} ${agent.lastName}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    const cityMatch =
      cities === "" || (agent.cities && agent.cities.includes(cities));
    const statusMatch =
      status === "" || (agent.status && agent.status.includes(status));

    return nameMatch && cityMatch && statusMatch;
  });

  useEffect(() => {
    let isMounted = true; // Track whether component is mounted

    async function fetchAgents() {
      try {
        const agentsCollection = collection(db, "agents");
        const agentsSnapshot = await getDocs(agentsCollection);

        const agentsData = [];
        agentsSnapshot.forEach((doc) => {
          const agent = { id: doc.id, ...doc.data() };
          agentsData.push(agent);
        });

        if (isMounted) {
          // Only update state if component is still mounted
          setAgents(agentsData);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching agents:", error);
        if (isMounted) {
          setLoading(false); // Ensure loading state is updated even on error
        }
      }
    }

    fetchAgents();

    return () => {
      isMounted = false; // Cleanup flag when component unmounts
    };
  }, []);

  // Calculate count of pending agents
  useEffect(() => {
    const countPendingAgents = agents.filter(
      (agent) => agent.status === "pending"
    ).length;
    setPendingAgentsCount(countPendingAgents);
  }, [agents]);

  return (
    <div className="bg-white">
      <div className="w-full min-h-screen flex mx-auto  overflow-hidden ">
        <div className="w-1/6 overflow-hidden h-screen bg-gray-100">
          <div className="w-full flex ">
            <TbRefresh
              onClick={() => window.location.reload()}
              className="text-gray-600 justify-end flex w-full text-lg cursor-pointer mt-4 hover:text-gray-900 transition duration-200"
              aria-label="Refresh Page"
            />
          </div>
          <ul className="items-center mx-auto justify-center w-full flex flex-col">
            <div className="flex justify-between items-center p-4">
              {/* Your Logo */}
              <img
                src={process.env.PUBLIC_URL + "/Logo.png"} // Replace with your logo path
                alt="Logo"
                className="w-32 m-4 cursor-pointer"
                onClick={() => navigate("/")}
              />
            </div>
            <div className="h-[1px] flex w-full bg-gray-300"></div>
            <li
              className="relative text-lg p-4 cursor-pointer w-full text-center hover:bg-gray-300 font-medium"
              onClick={() => setSelectedSection("listings")}
            >
              Listings
              {pendingListingsCount > 0 && ( // Render the circle if there are pending regular listings
                <span className="absolute text-xs top-2 right-2 bg-red-500 text-white rounded-full px-2 py-1 flex items-center justify-center">
                  {pendingListingsCount}
                </span>
              )}
            </li>
            <div className="h-[1px] flex w-full bg-gray-300"></div>
            <li
              className="relative text-lg p-4 cursor-pointer w-full text-center hover:bg-gray-300 font-medium"
              onClick={() => setSelectedSection("beyttyListings")}
            >
              Beytty
              {pendingBeyttyListingsCount > 0 && ( // Render the circle if there are pending beytty listings
                <span className="absolute text-xs top-2 right-2 bg-red-500 text-white rounded-full px-2 py-1 flex items-center justify-center">
                  {pendingBeyttyListingsCount}
                </span>
              )}
            </li>
            <div className="h-[1px] flex w-full bg-gray-300"></div>
            <li
              className="relative text-lg p-4 cursor-pointer w-full text-center hover:bg-gray-300 font-medium"
              onClick={() => setSelectedSection("agents")}
            >
              Agents
              {pendingAgentsCount > 0 && ( // Render the circle if there are pending agents
                <span className="absolute top-2 right-2 bg-red-500 text-white rounded-full px-2 py-1 flex items-center justify-center text-xs">
                  {pendingAgentsCount}
                </span>
              )}
            </li>
            <div className="h-[1px] flex w-full bg-gray-300"></div>
            <li
              className="relative text-lg p-4 cursor-pointer w-full text-center hover:bg-gray-300 font-medium"
              onClick={() => setSelectedSection("reviews")}
            >
              Reviews
            </li>
            <div className="h-[1px] flex w-full bg-gray-300"></div>
            <li
              className="relative text-lg p-4 cursor-pointer w-full text-center hover:bg-gray-300 font-medium"
              onClick={() => setSelectedSection("map")}
            >
              Map
            </li>
            <div className="h-[1px] flex w-full bg-gray-300"></div>
          </ul>
        </div>
        <div className="lg:block hidden w-full h-screen overflow-y-auto ">
          {selectedSection === "listings" && (
            <div className=" rounded">
              <div className="flex  w-full ">
                <div className="w-full  bg-white ">
                  <div className="flex p-8 gap-2 w-full rounded">
                    <div className="w-1/2">
                      <p className="mb-1 font-semibold">Listing Id</p>
                      <input
                        type="text"
                        placeholder="Search by Id"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-full border border-gray-300 rounded"
                      />
                    </div>

                    <div className="w-1/2">
                      <p className="mb-1 font-semibold">Filter by Status</p>
                      <select
                        value={statusFilter}
                        onChange={handleStatusFilterChange}
                        className="w-full border border-gray-300 rounded"
                      >
                        <option value="">All</option>
                        <option value="pending">Pending</option>
                        <option value="approved">Approved</option>
                        <option value="noanswerone">No answer 1</option>
                        <option value="noanswertwo">No answer 2</option>
                        <option value="noanswerthree">No answer 3</option>
                        <option value="pendingsold">Pending Sold</option>
                        <option value="sold">Sold</option>
                        <option value="canceled">Canceled</option>
                      </select>
                    </div>
                  </div>
                  <div className="hidden lg:block px-8 mt-4">
                    <>
                      {!loading && listings.length > 0 && (
                        <ul className="flex md:gap-10 py-2 md:py-4  font-semibold">
                          <li className="flex w-1/6 text-sm md:text-md">
                            Listing
                          </li>
                          <li className=" w-1/6 text-sm md:text-md">Address</li>
                          <li className="w-1/6 text-sm md:text-md">Type</li>
                          <li className="flex w-1/6 text-sm md:text-md">
                            Price
                          </li>
                          <li className="flex w-1/6 text-sm md:text-md"></li>
                          <li className="flex w-1/5 text-sm md:text-md">
                            <p className="ml-auto">Status</p>
                          </li>
                        </ul>
                      )}
                      <div className="w-full bg-gray-200 h-[2px]"></div>
                      {filteredListings.length === 0 ? (
                        <p className="mt-4 text-center mb-4">
                          Sorry, we couldn't find any listings matching your
                          criteria.
                        </p>
                      ) : (
                        <ul className="">
                          {!loading &&
                          (filteredListings.length > 0 ||
                            !searchQuery ||
                            !statusFilter) ? (
                            (searchQuery || statusFilter
                              ? filteredListings
                              : currentListings
                            ).map((listing) => (
                              <li
                                key={listing.id}
                                className="text-xs  sm:text-sm "
                              >
                                <div className="flex  py-2 md:py-4 hover:bg-gray-100 rounded-md">
                                  <div
                                    className="flex w-full cursor-pointer "
                                    onClick={() =>
                                      navigate(`/listingdetails/${listing.id}`)
                                    }
                                  >
                                    <div className="flex w-1/5">
                                      <img
                                        src={
                                          listing.data.imgUrls ||
                                          "/placeholder-image.jpg"
                                        }
                                        alt=""
                                        className="w-2/3 h-20 object-cover rounded-md mb-2"
                                      />
                                    </div>
                                    <div className="w-1/5">
                                      <p className="flex capitalize text-gray-700 mb-1">
                                        {listing.data.address.slice(0, 15)}
                                        {listing.data.address.length > 15 &&
                                          "..."}
                                      </p>
                                    </div>

                                    <div className="w-1/5">
                                      <p className="flex capitalize mb-1">
                                        {listing.data.listingType} for{" "}
                                        {listing.data.type}
                                      </p>
                                    </div>
                                    <div className="flex font-semibold w-1/5 ">
                                      <p className="flex font-semibold ">
                                        {listing.data.regularPrice}DH
                                      </p>
                                    </div>
                                    <div className="flex  w-1/5"></div>
                                  </div>

                                  <div className="ml-auto w-1/5">
                                    <p className="flex items-center w-full justify-end">
                                      <select
                                        className={`mr-2 rounded-xl border-2 ${
                                          listing.data.status === "pending"
                                            ? "border-orange-500 text-orange-500"
                                            : listing.data.status === "approved"
                                            ? "border-green-500 text-green-500"
                                            : listing.data.status ===
                                              "pendingsold"
                                            ? "border-blue-500 text-blue-500"
                                            : listing.data.status === "sold"
                                            ? "border-blue-500 text-blue-500"
                                            : listing.data.status ===
                                              "noanswerone"
                                            ? "border-red-500 text-red-500"
                                            : listing.data.status ===
                                              "noanswertwo"
                                            ? "border-red-600 text-red-600"
                                            : listing.data.status ===
                                              "noanswerthree"
                                            ? "border-red-700 text-red-700"
                                            : listing.data.status === "canceled"
                                            ? "border-gray-500 text-gray-500"
                                            : ""
                                        }`}
                                        value={listing.data.status}
                                        onChange={(e) =>
                                          handleStatusChange(e, listing.id)
                                        }
                                      >
                                        <option value="pending">Pending</option>
                                        <option value="approved">
                                          Approved
                                        </option>
                                        <option value="noanswerone">
                                          No answer 1
                                        </option>
                                        <option value="noanswertwo">
                                          No answer 2
                                        </option>
                                        <option value="noanswerthree">
                                          No answer 3
                                        </option>
                                        <option value="pendingsold">
                                          Pending Sold
                                        </option>
                                        <option value="sold">Sold</option>
                                        <option value="canceled">
                                          Canceled
                                        </option>
                                      </select>
                                      <MdDelete
                                        className="cursor-pointer hidden text-xl hover:opacity-50 text-red-700"
                                        onClick={() => onDelete(listing.id)}
                                      />
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))
                          ) : (
                            <div>You currently have no listings.</div>
                          )}
                        </ul>
                      )}
                    </>
                  </div>
                  <div className="hidden lg:flex mt-4 mb-4  font-semibold pagination gap-4 justify-center">
                    {listings.length > 0 && (
                      <button
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        <GrPrevious />
                      </button>
                    )}

                    {Array.from({ length: totalPages }, (_, index) => {
                      // Check if the page number is one of the current, previous, or next three pages
                      if (
                        index + 1 === currentPage ||
                        index + 2 === currentPage ||
                        index === currentPage
                      ) {
                        return (
                          <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            style={{
                              backgroundColor:
                                currentPage === index + 1
                                  ? "#ffebeb"
                                  : "transparent",
                              border:
                                currentPage === index + 1
                                  ? "2px solid red"
                                  : "1px solid transparent",
                              color:
                                currentPage === index + 1 ? "black" : "#333",
                              padding: "5px 10px",
                              borderRadius: "50%",
                              cursor: "pointer",
                              width: "40px", // Adjust width as needed for circular buttons
                              height: "40px", // Adjust height as needed for circular buttons
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {index + 1}
                          </button>
                        );
                      }
                      // Show ellipses (...) for pages not included in the current, previous, or next three pages
                      else if (index === 0 || index === totalPages - 1) {
                        return (
                          <span key={index} style={{ padding: "5px 10px" }}>
                            ...
                          </span>
                        );
                      }
                      return null;
                    })}

                    {listings.length > 0 && (
                      <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                      >
                        <GrNext />
                      </button>
                    )}
                  </div>
                  <div className="flex flex-col items-center mx-auto justify-center mt-4 mb-8 lg:hidden">
                    <CiLaptop style={{ height: "100px", width: "100px" }} />
                    <p>Please use pc to access listings.</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedSection === "beyttyListings" && (
            <div className=" rounded">
              <div className="flex w-full">
                <div className="w-full  bg-white ">
                  <div className="flex p-8 gap-2 w-full rounded">
                    <div className="w-1/2">
                      <p className="mb-1 font-semibold">Listing Id</p>
                      <input
                        type="text"
                        placeholder="Search by Id"
                        value={beyttySearchQuery}
                        onChange={(e) => setBeyttySearchQuery(e.target.value)}
                        className="w-full border border-gray-300 rounded"
                      />
                    </div>

                    <div className="w-1/2">
                      <p className="mb-1 font-semibold">Filter by Status</p>
                      <select
                        value={beyttyStatusFilter}
                        onChange={handleBeyttyStatusFilterChange}
                        className="w-full border border-gray-300 rounded"
                      >
                        <option value="">All</option>
                        <option value="pending">Pending</option>
                        <option value="approved">Approved</option>
                        <option value="noanswerone">No answer 1</option>
                        <option value="noanswertwo">No answer 2</option>
                        <option value="noanswerthree">No answer 3</option>
                        <option value="pendingsold">Pending Sold</option>
                        <option value="sold">Sold</option>
                        <option value="canceled">Canceled</option>
                      </select>
                    </div>
                  </div>
                  <div className="hidden lg:block px-8 mt-4">
                    <>
                      {!loading && beyttyListings.length > 0 && (
                        <ul className="flex md:gap-10 py-2 md:py-4  font-semibold">
                          <li className="flex w-1/6 text-sm md:text-md">
                            Listing
                          </li>
                          <li className=" w-1/6 text-sm md:text-md">Address</li>
                          <li className="w-1/6 text-sm md:text-md">Type</li>
                          <li className="flex w-1/6 text-sm md:text-md">
                            Price
                          </li>
                          <li className="flex w-1/6 text-sm md:text-md">
                            Pack
                          </li>
                          <li className="flex w-1/5 text-sm md:text-md">
                            <p className="ml-auto">Delete</p>
                          </li>
                        </ul>
                      )}
                      <div className="w-full bg-gray-200 h-[2px]"></div>
                      {filteredBeyttyListings.length === 0 ? (
                        <p className="mt-4 text-center mb-4">
                          Sorry, we couldn't find any listings matching your
                          criteria.
                        </p>
                      ) : (
                        <ul className="">
                          {!loading &&
                          (filteredBeyttyListings.length > 0 ||
                            !beyttySearchQuery ||
                            !beyttyStatusFilter) ? (
                            (beyttySearchQuery || beyttyStatusFilter
                              ? filteredBeyttyListings
                              : currentBeyttyListings
                            ).map((listing) => (
                              <li
                                key={listing.id}
                                className="text-xs sm:text-sm "
                              >
                                <div
                                  className={`flex px-1 py-2 md:py-4 rounded-md ${
                                    listing.data.pack === "premium"
                                      ? "bg-red-100 hover:bg-red-200"
                                      : "bg-yellow-100 hover:bg-yellow-200"
                                  }`}
                                >
                                  <div
                                    className="flex w-full cursor-pointer "
                                    onClick={() =>
                                      navigate(`/beyttydetails/${listing.id}`)
                                    }
                                  >
                                    <div className="flex w-1/5 ">
                                      <img
                                        src={
                                          listing.data.imgUrls ||
                                          "/placeholder-image.jpg"
                                        }
                                        alt=""
                                        className="w-2/3 h-20 object-cover rounded-md mb-2"
                                      />
                                    </div>
                                    <div className="w-1/5">
                                      <p className="flex capitalize text-gray-700 mb-1">
                                        {listing.data.address.slice(0, 15)}
                                        {listing.data.address.length > 15 &&
                                          "..."}
                                      </p>
                                    </div>

                                    <div className="w-1/5">
                                      <p className="flex capitalize mb-1">
                                        {listing.data.listingType} for{" "}
                                        {listing.data.type}
                                      </p>
                                    </div>

                                    <div className="flex font-semibold w-1/5 ">
                                      <p className="flex font-semibold ">
                                        {listing.data.regularPrice}DH
                                      </p>
                                    </div>
                                    <div className="w-1/5">
                                      <p className="flex capitalize mb-1">
                                        {listing.data.pack}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="ml-auto w-1/5">
                                    <p className="flex items-center w-full justify-end">
                                      <select
                                        className={`mr-2 rounded-xl border-2 ${
                                          listing.data.status === "pending"
                                            ? "border-orange-500 text-orange-500"
                                            : listing.data.status === "approved"
                                            ? "border-green-500 text-green-500"
                                            : listing.data.status ===
                                              "scheduled"
                                            ? "border-blue-500 text-blue-500"
                                            : listing.data.status === "noanswer"
                                            ? "border-red-500 text-red-500"
                                            : listing.data.status ===
                                              "noanswertwo"
                                            ? "border-red-600 text-red-600"
                                            : listing.data.status ===
                                              "noanswerthree"
                                            ? "border-red-700 text-red-700"
                                            : listing.data.status === "canceled"
                                            ? "border-gray-500 text-gray-500"
                                            : ""
                                        }`}
                                        value={listing.data.status}
                                        onChange={(e) =>
                                          handleBeyttyStatusChange(
                                            e,
                                            listing.id
                                          )
                                        }
                                      >
                                        <option value="pending">Pending</option>
                                        <option value="contacted">
                                          Contacted
                                        </option>
                                        <option value="scheduled">
                                          Scheduled
                                        </option>
                                        <option value="noanswer">
                                          No answer 1
                                        </option>
                                        <option value="noanswertwo">
                                          No answer 2
                                        </option>
                                        <option value="noanswerthree">
                                          No answer 3
                                        </option>
                                        <option value="canceled">
                                          Canceled
                                        </option>
                                      </select>
                                      <MdDelete
                                        className="cursor-pointer text-xl hover:opacity-50 text-red-700"
                                        onClick={() =>
                                          onBeyttyDelete(listing.id)
                                        }
                                      />
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))
                          ) : (
                            <div>You currently have no listings.</div>
                          )}
                        </ul>
                      )}
                    </>
                  </div>
                  <div className="hidden lg:flex mt-4 mb-4  font-semibold pagination gap-4 justify-center">
                    {beyttyListings.length > 0 && (
                      <button
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        <GrPrevious />
                      </button>
                    )}

                    {Array.from({ length: totalPages }, (_, index) => {
                      // Check if the page number is one of the current, previous, or next three pages
                      if (
                        index + 1 === currentPage ||
                        index + 2 === currentPage ||
                        index === currentPage
                      ) {
                        return (
                          <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            style={{
                              backgroundColor:
                                currentPage === index + 1
                                  ? "#ffebeb"
                                  : "transparent",
                              border:
                                currentPage === index + 1
                                  ? "2px solid red"
                                  : "1px solid transparent",
                              color:
                                currentPage === index + 1 ? "black" : "#333",
                              padding: "5px 10px",
                              borderRadius: "50%",
                              cursor: "pointer",
                              width: "40px", // Adjust width as needed for circular buttons
                              height: "40px", // Adjust height as needed for circular buttons
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {index + 1}
                          </button>
                        );
                      }
                      // Show ellipses (...) for pages not included in the current, previous, or next three pages
                      else if (index === 0 || index === totalPages - 1) {
                        return (
                          <span key={index} style={{ padding: "5px 10px" }}>
                            ...
                          </span>
                        );
                      }
                      return null;
                    })}

                    {beyttyListings.length > 0 && (
                      <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                      >
                        <GrNext />
                      </button>
                    )}
                  </div>
                  <div className="flex flex-col items-center mx-auto justify-center mt-4 mb-8 lg:hidden">
                    <CiLaptop style={{ height: "100px", width: "100px" }} />
                    <p>Please use pc to access listings.</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedSection === "agents" && (
            <div className=" rounded">
              <div className="flex w-full">
                <div className="w-full p-8 bg-white ">
                  <div className="sm:flex p-4 rounded-xl sm:p-8 gap-4 bg-gray-100 mb-8">
                    {" "}
                    <div className="w-full">
                      <p className="mb-1 font-semibold">Name</p>
                      <input
                        type="text"
                        placeholder="Search by name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-full border border-gray-300 rounded-xl"
                      />
                    </div>
                    <div className="mt-4 sm:mt-0 flex w-full gap-4">
                      <div className=" w-full ">
                        <p className="mb-1 font-semibold">Status</p>
                        <select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          className="p-2 w-full border border-gray-300 rounded-xl"
                        >
                          <option value="">All </option>
                          <option value="pending">Pending</option>
                          <option value="approved">Approved</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <div>
                      {filteredAgents.length === 0 ? (
                        <p className="sm:text-xl text-center mb-4">
                          Sorry, we couldn't find any agents matching your
                          criteria.
                        </p>
                      ) : (
                        <ul>
                          {filteredAgents.map((agent) => (
                            <li
                              key={agent.id}
                              className="flex rounded-xl  bg-gray-100 mb-4 py-4 sm:px-8 transform transition-all ease-in-out duration-150 "
                            >
                              <img
                                src={
                                  agent.photoURL
                                    ? agent.photoURL
                                    : "/anonym.png"
                                }
                                alt={`Profile of ${agent.firstName} ${agent.lastName}`}
                                className="h-16 w-16 sm:h-20 sm:w-20  rounded-full object-cover"
                              />
                              <div className="flex-col px-8 w-full">
                                <Link
                                  to={`/agent/${agent.id}`}
                                  className="sm:text-xl hover:text-red-700 hover:underline font-semibold"
                                >{`${agent.firstName} ${agent.lastName}`}</Link>
                                <a
                                  href={`tel:${agent.phoneNumber}`}
                                  className="flex text-sm md:text-lg w-full"
                                >
                                  {agent.phoneNumber}
                                </a>

                                <p className="text-sm sm:text-md font-light opacity-90">
                                  {agent.agency}
                                </p>
                              </div>
                              <p className="flex w-full items-center justify-end">
                                <select
                                  className={`mr-2 rounded-xl w-1/3 h-10 border-2 ${
                                    agent.status === "pending"
                                      ? "border-orange-500 text-orange-500"
                                      : agent.status === "approved"
                                      ? "border-green-500 text-green-500"
                                      : agent.status === "noanswerone"
                                      ? "border-red-500 text-red-500"
                                      : agent.status === "noanswertwo"
                                      ? "border-red-500 text-red-500"
                                      : agent.status === "noanswerthree"
                                      ? "border-red-500 text-red-500"
                                      : agent.status === "contacted"
                                      ? "border-blue-500 text-blue-500"
                                      : agent.status === "canceled"
                                      ? "border-red-500 text-red-500"
                                      : ""
                                  }`}
                                  value={agent.status}
                                  onChange={(e) =>
                                    handleAgentStatusChange(e, agent.id)
                                  }
                                >
                                  <option value="pending">Pending</option>
                                  <option value="approved">Verified</option>
                                  <option value="noanswerone">
                                    No aswer 1
                                  </option>
                                  <option value="noanswertwo">
                                    No answer 2
                                  </option>
                                  <option value="noanswerthree">
                                    No answer 3
                                  </option>
                                  <option value="contacted">Contacted</option>
                                  <option value="canceled">Canceled</option>
                                </select>
                              </p>
                              <Link
                                to={`/agent/${agent.id}`}
                                className="flex text-2xl items-center md:text-4xl w-1/5 hover:text-red-700 justify-end"
                              >
                                <GrNext />
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {selectedSection === "reviews" && (
            <div className=" rounded">
              <div className="flex w-full">
                <div className="w-full p-8 bg-white ">
                  {loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className="mb-4 font-semibold text-lg">
                        Flagged Reviews
                      </div>
                      {flaggedReviews.length > 0 ? (
                        flaggedReviews.map((review) => (
                          <div
                            className="rounded mb-2 w-full bg-gray-100 p-4"
                            key={review.id}
                          >
                            <p>{review.data.comment}</p>
                            <p className="text-sm font-light">
                              {review.data.reportedAt.toDate().toLocaleString()}
                            </p>
                            <MdDelete
                              className="ml-auto mb-4 cursor-pointer text-red-700"
                              onClick={() => handleDeleteReview(review.id)}
                            />
                            <MdDone
                              className="ml-auto text-lg cursor-pointer text-green-700"
                              onClick={() => clearReportReview(review.id)}
                            />
                          </div>
                        ))
                      ) : (
                        <p>{t("No flagged reviews found.")}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {selectedSection === "map" && (
            <div className="h-screen rounded">
              <div className="flex h-screen w-full">
                <div className="w-full h-screen bg-white ">
                  {loading ? (
                    <Spinner />
                  ) : (
                    <div className="w-full h-screen ">
                      <ReactMapGL
                        {...viewport}
                        mapStyle="mapbox://styles/mohamedmakdad/clsqz4sct00xn01pf8o9vg38g"
                        mapboxApiAccessToken={
                          process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
                        }
                        onMove={(evt) => setViewport(evt.viewport)}
                      >
                        {renderPolygons()}
                      </ReactMapGL>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
