import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";


const CookiesBanner = () => {
  const { t } = useTranslation(); 
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setIsVisible(false);
  };

  const handleDecline = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="flex-col cookie-banner">
      <div>
        <p className="text-black">
          {t("cookiesBanner.message")}
        </p>
      </div>
      <div className="flex gap-2">
        <button onClick={handleAccept} className="btn">
          {t("cookiesBanner.accept")} 
        </button>
        <button onClick={handleDecline} className="btn btn-secondary">
          {t("cookiesBanner.decline")}
        </button>
      </div>
    </div>
  );
};

export default CookiesBanner;
